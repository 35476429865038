/**
 * Mixin to normalize headers
 */
export default {
  computed: {
    /**
     * Use computed to cache the result of normalize
     */
    formattedHeaders() {
      return this._normalizeHeadersObject(this.headers);
    },
  },
  methods: {
  /**
   * Normalize headers
   * Transform headers array in object in default entry
   *
   * @param {object[] | Record<string, object[]>} headers headers to normalize
   *
   * @return {Record<string, object>} Object of headers
   */
    _normalizeHeadersObject(headers) {
      if (Array.isArray(headers)) {
        return {
          default: headers,
        };
      }
      return { ...headers };
    },
  },
};
