<template>
  <div style="position: relative;">
    <component
      ref="Chart"
      v-bind:is="chartComponent"
      :chart-data="computedData"
      :chart-options="options"
      @click="onClick"
      :styles="{ height: compHeight, width: '100%' }"
    />
    <div
      ref="popup"
      v-show="highlighted !== -1"
      class="chartPopup"
    >
      <DataPopup
        :headers="formattedHeaders.default"
        :hit="hits[highlighted]"
      />
    </div>
  </div>
</template>
<script>
import { fr, enUS } from 'date-fns/locale';
import normalizeHeadersMixin from '../../mixins/normalizeHeaders';
import getChart from '../../misc/chart';
import dataAccess from '../../misc/dataAccess';
import _ from '../../misc/lodash';
import DataPopup from './DataPopup.vue';

const dateLocales = {
  en: enUS,
  fr,
};

/**
 * Interface for vue-chart component and handled popup with DataPopup
 */
export default {
  name: 'chart',
  components: {
    DataPopup,
  },
  mixins: [
    normalizeHeadersMixin,
  ],
  props: {
    type: {
      type: String,
      default: 'line',
    },
    headers: {
      type: [Object, Array],
      default: () => {},
    },
    hits: {
      type: Array,
      default: () => [],
    },
    seriesOptions: {
      type: [Object, Array],
      default: () => ([]),
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    height: {
      type: [String, Number],
      default: '350px',
    },
  },
  data: () => ({
    options: {
      tooltips: {
        enabled: false,
      },
    },
    computedData: { datasets: [] },
    deboucedComputeData: () => {},
  }),
  computed: {
    /**
     * Get chart component
     *
     * @return {object} vue component object
     */
    chartComponent() {
      return getChart(this.type);
    },
    /**
     * Compute seriesOptions to normalize format and extract data path
     *
     * @return {Chart.ChartDataSets[]} Normalized seriesOptions
     */
    computedOptions() {
      const seriesOptions = Array.isArray(this.seriesOptions)
        ? this.seriesOptions
        : [this.seriesOptions];

      return seriesOptions.map((serie) => {
        const [{ path: xpath }, { path: ypath }] = [
          this.headers.find(({ fid }) => fid === serie.data.x) || {},
          this.headers.find(({ fid }) => fid === serie.data.y) || {},
        ];
        return { ...serie, xpath, ypath };
      });
    },
    /**
     * Return the index of hit focused
     * Return -1 if is not found
     *
     * @return {number} number of index
     */
    highlighted() {
      return this.hits.findIndex((hit) => hit.focus);
    },
    /**
     * Compute height of compunent.
     * Normalize result if height is string or number
     *
     * @return {string} string of height style in px
     */
    compHeight() {
      const height = Number.isNaN(Number(this.height))
        ? Number(this.height.replace('px', ''))
        : this.height;
      return `${height}px`;
    },
  },
  watch: {
    /**
     * Update the options with locale when locale change
     */
    '$i18n.locale': {
      handler() {
        this.options = this.translatedOptions(this.options);
      },
    },
    /**
     * Active the segment highlighted on chart
     * Need update draw of chart
     *
     * @param {string|number} highlighted Key of hit highlighted
     */
    highlighted(highlighted) {
      const chart = this.$refs.Chart.$data._chart;

      if (highlighted !== -1) {
        const segment = chart.getDatasetMeta(0).data[highlighted];
        chart.tooltip._active = [segment];
        chart.tooltip.update(true);
        chart.draw();
      }
    },

    computedOptions: {
      handler() {
        this.deboucedComputeData();
      },
      deep: true,
      immediate: true,
    },
    hits() {
      this.deboucedComputeData();
    },
  },
  /**
   * Set the options before mount to be ready at mount of chart
   */
  beforeMount() {
    if (typeof this.chartOptions !== 'undefined') {
      this.options = this.translatedOptions({ ...this.options, ...this.chartOptions });
    }

    // Set position popup
    this.options.tooltips.custom = (tooltipModel) => {
      // Just wait a render of popup for can get a realBounds
      setTimeout(() => {
        const chartBounds = this.$refs.Chart.$refs.canvas.getBoundingClientRect();
        const tooltipEl = this.$refs.popup;

        let leftPos = tooltipModel.caretX;
        const rightPos = leftPos + tooltipEl.offsetWidth; // Left + popup width

        // If right angle is out of bounds
        if (rightPos > chartBounds.width) {
          // Substract tooltip width on coordinate to move tooltip to left of point
          leftPos -= tooltipEl.offsetWidth;
        }

        tooltipEl.style.left = `${leftPos}px`;
        tooltipEl.style.top = `${tooltipModel.caretY}px`;
      }, 0);
    };
  },
  mounted() {
    this.deboucedComputeData = _.debounce(() => {
      this.computeData();
    }, 500);
  },
  methods: {
    /**
     * Compute translation in options
     *
     * @param {Chart.ChartOptions} options object of options with string to translate
     *
     * @return {Chart.ChartOptions} object with translation
     */
    translatedOptions(options) {
      // Set locale date adapter and copy options
      let _options = _.set({ ...options }, 'scales.xAxes[0].adapters.date', {
        locale: dateLocales[this.$i18n.locale],
      });

      _options = _.set(_options, 'scales.xAxes[0].time', {
        ...dataAccess.get(_options, 'scales.xAxes[0].time', {}),
        displayFormats: {
          minute: 'p',
          hour: 'p',
          ...dataAccess.get(_options, 'scales.xAxes[0].time.displayFormats', {}),
        },
      });

      // Prevent error when legend is true
      if (_options.legend === true) {
        delete _options.legend;
      }

      // If title is active
      if (typeof options.title === 'object'
        && options.title !== null
        && options.title.display === true) {
        // Translate the title
        _options = _.set(_options, 'title.text', this.$t(this.chartOptions.title.text));
      }
      return _options;
    },

    computeData() {
      const datasets = this.computedOptions.map((serie) => {
        const data = this.hits.map((hit) => ({
          ...hit,
          x: parseInt(dataAccess.get(hit.data, serie.xpath), 10),
          y: dataAccess.get(hit.data, serie.ypath),
        }));
        // If legends are active
        if (this.options.legend !== false) {
          // Translate label
          serie.label = this.$t(serie.label);
        }
        return { ...serie, data };
      });

      this.computedData = { datasets };
    },
    /**
     * Emit id of hit clicked to parent
     *
     * @param  {MouseEvent} event
     * @param  {Record<string, unknown>[]} points
     */
    onClick(event, points) {
      // If click on point
      if (points.length > 0) {
        // Repeat event click to the parent
        this.$emit('click', this.hits[points[0]._index].id);
      }
    },
  },
};
</script>

<style>
.chartPopup {
  position: absolute;
  padding: 10px;
  background-color: #ffffff;
  pointer-events: none;
  font-size: 12px;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}
</style>
