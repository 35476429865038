import 'chartjs-adapter-date-fns';
import { defaults } from 'chart.js';
import { generateChart, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

/**
 * Default global configuration
 */
defaults.global.animation.duration = 0; // For disable animations
defaults.global.events = ['click']; // For disable tooltips on hover
defaults.global.maintainAspectRatio = false; // Do not overflow

// Function for generate Chart component
export default function getChart(type) {
  return {
    extends: generateChart(`${type}-chart`, type),
    mixins: [reactiveProp],
    props: {
      chartOptions: Object,
    },
    computed: {
      options() {
        const options = {
          autoMargin: true,
          ...this.chartOptions,
          // Prevent side effect with auto margin
          scales: { ...this.chartOptions.scales },
          onClick: this.onClick,
        };

        if (options.autoMargin) {
          const { yAxes = [] } = this.chartOptions.scales;
          // Check if min/max (or suggested) value are already set
          const scaleIsSet = yAxes.some(
            (conf) => conf.ticks && (
              'suggestedMax' in conf.ticks || 'suggestedMin' in conf.ticks
                  || 'max' in conf.ticks || 'min' in conf.ticks
            ),
          );

          // If min/max are not set, compute this for the current data
          if (!scaleIsSet) {
            if (typeof options.scales.yAxes === 'undefined') {
              options.scales.yAxes = [];
            }
            const yData = this.chartData.datasets.reduce(
              (acc, set) => ([...acc, ...set.data.map(({ y }) => y)]),
              [],
            ).filter((entry) => typeof entry !== 'undefined');

            if (yData.length > 0) {
              options.scales.yAxes.push({
                ticks: {
                  suggestedMin: Math.min(...yData) - 1,
                  suggestedMax: Math.max(...yData) + 1,
                },
              });
            }
          }
        }
        return options;
      },
    },
    watch: {
      /**
       * Watch options to update the chart options to add reactivity on options
       */
      options(value) {
        this.$data._chart.options = value;
        this.$data._chart.update();
      },
    },
    mounted() {
      /**
       * Render the chart when component is mounted (available in DOM)
       */
      this.renderChart(this.chartData, this.options);
    },
    methods: {
      /**
       * Handle click on chart
       * @param  {MouseEvent} event
       * @param  {Record<string, unknown>[]} points
       */
      onClick(event, points) {
        this.$emit('click', event, points);
      },
    },
  };
}
