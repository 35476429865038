<template>
  <v-dialog
    v-model="openModal"
    max-width="300px"
  >
    <v-card>
      <Filters
        :filters="filters"
        @update:filters="change"
      ></Filters>
      <v-divider></v-divider>
      <v-card-actions>
        <v-layout justify-end>
          <v-btn
            color="primary"
            text v-on:click="openModal = false"
          >
            {{ $t('misc.close') }}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        dark
        v-on="on"
      >
        <div>{{$t('misc.filters')}}</div>
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import Filters from './Filters.vue';

/**
 * Wrap the Filters in popup with button to open
 */
export default {
  name: 'FiltersPopup',
  components: {
    Filters,
  },
  props: {
    filters: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data: () => ({
    openModal: false,
  }),
  methods: {
    /**
     * Repeat change event on parent
     */
    change(event) {
      this.$emit('update:filters', event);
    },
  },
};
</script>
