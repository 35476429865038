<template>
  <div class="v-layout-field v-text-field">
    <v-layout row mb-3 align-center v-if="(showFilters || layoutsNb > 1) && !filtersProp">
      <v-flex xs6>
        <v-subheader
          light
          class="v-label v-label--active pl-0"
          style="margin-bottom: -30px;"
        >
          {{ $t(currentViewLabel) }}
        </v-subheader>
      </v-flex>
      <v-flex xs6 class="text-right">
        <filters-popup
          v-if="showFilters"
          :filters.sync="filters"
        />
        <v-icon
          v-for="(layout, key) in layoutsIcons"
          v-on:click="switchLayout(key, layout)"
          :color="(show === key)? 'primary' : 'grey'"
          :title="$t(layout.label)"
          :key="key"
        >
          {{ layout.icon }}
        </v-icon>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-3
      v-if="filtersProp"
    >
      <v-flex xs12>
        <v-text-field
          class="search"
          v-on:input="updateSearch"
          :label="$t('crud.searchByNameIdTown')"
          append-icon="mdi-magnify"
          solo
          v-model="currentSearch"
          v-on:click:append="updateSearch"
        >
          <template slot="append-outer">
            <slot name="filter"></slot>
          </template>
        </v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row mb-3 align-top>
      <v-flex
        xs12
        :lg6="sideComponents"
        v-show="canShow('Table', show)"
      >
        <Table
          class="ma-0 pa-0"
          :object="object"
          :headers="compHeader['Table']"
          :hits="hits['Table']"
          :focused="tableFocused"
          :pagination.sync="pagination"
          :sort.sync="sort"
          :total="total"
          :loading="loading"
          :searchable="false"
          v-on:click="highlight"
          :height="height"
        ></Table>
      </v-flex>
      <v-flex
        xs12
        :lg6="sideComponents"
        v-show="canShow('Map', show)"
      >
        <Map
          ref="map"
          :height="height"
          :object="object"
          :mapLayers="mapLayers"
          :headers="headerMap"
          :hits="hits['Map']"
          :loading="loading"
          :linked="true"
          :autoZoom="true"
          v-on:click="highlight"
        />
      </v-flex>
      <v-flex
        xs12
        :lg6="sideComponents"
        v-show="canShow('timeLineMapField', show)"
      >
        <timeLineMapField
          ref="timeLineMapField"
          :height="height"
          :object="object"
          :loading="loading"
          :linked="true"
          :autoZoom="true"
          :readonly="true"
          :extLoading="false"
          :fields="timelineReadFields"
          :mapLayers="timelineMapLayers"
          :seriesData="seriesData"
          :options="{}"
          v-on:click="highlight"
        />
      </v-flex>
      <v-flex
        xs12
        :lg6="sideComponents"
        v-show="canShow('Chart', show)"
      >
        <Chart
          ref="chart"
          v-if="components.Chart"
          :headers="compHeader['Chart']"
          :hits="hits['Chart']"
          v-bind="components.Chart"
          v-on:click="highlight"
          :height="height"
        />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import _ from '@/misc/lodash';
import { buildAllGeoObjects } from '@/misc/buildGeoObject';
import mapFieldMixin from '@/mixins/mapField';
import FilterableMixin from '@/mixins/filterable';
import tableSearchPreferences from '@/mixins/tableSearchPreferences';
import Table from '@/components/crud/Table.vue';
import Map from '@/components/Map.vue';
import Chart from '@/components/crud/Chart.vue';
import FiltersPopup from '@/components/crud/FiltersPopup.vue';
import TimeLineMapField from './timeLineMapField.vue';

export default {
  mixins: [
    mapFieldMixin,
    FilterableMixin,
    tableSearchPreferences,
  ],
  components: {
    Table,
    Map,
    Chart,
    FiltersPopup,
    TimeLineMapField,
  },
  props: {
    seriesData: {
      type: Object,
      default: () => ({}),
    },
    timelineMapLayers: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: 'label.layoutField',
    },
    components: {
      type: Object,
      default: () => ({
        Table: {},
        Map: {},
      }),
    },
    layouts: {
      type: Object,
      default: () => ({}),
    },
    defaultLayout: {
      type: String,
      default: null,
    },
    filtersProp: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    pagination: {
      page: 1,
      itemsPerPage: 15,
    },
    sort: {
      /*
      It't a path => order object
      path: 'ASC',
    */
    },
    loading: false,
    total: 0,
    show: null,
    focus: null,
    tableFocused: [],
    hits: {},
    height: 338,
    resizeListener: null,
    fetchDataDebounce: () => {},
    currentViewLabel: '',
    currentSearch: '',
    search: '',
  }),
  computed: {
    /**
     * Compute numbers of layout
     *
     * @return {number}
     */
    layoutsNb() {
      return Object.keys(this.layouts).length;
    },
    sideComponents() {
      return this.layouts[this.show].components.length > 1;
    },
    linked() {
      return _.get(this.options, 'linked', false);
    },
    conditionsComponents() {
      return _.get(this.options, 'conditions', []).reduce((acc, { components, ...condition }) => {
        (components || []).forEach((cmpName) => {
          if (typeof acc[cmpName] === 'undefined') {
            acc[cmpName] = [];
          }
          acc[cmpName].push(condition);
        });
        return acc;
      }, {});
    },
    activeComps() {
      return Object.keys(this.components);
    },
    headers() {
      return this.readFields.map((header) => ({
        text: this.$t(header.label),
        name: this.$t(header.label),
        value: header.path,
        sortable: !!header.sortable,
        ...header,
      }));
    },
    compHeader() {
      return this.activeComps.reduce((acc, comp) => ({
        ...acc,
        [comp]: this.headers.filter(
          ({ display }) => ((display && display.includes(comp)) || false),
        ),
      }), {});
    },
    headerMap() {
      // TODO look map error ('type' of undefined)
      return {
        [this.alias]: this.compHeader.Map,
      };
    },
    dataQuery() {
      const query = {
        id: this.objectId,
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
        ...this.queryParams,
        filters: this.filterValues,
      };
      if (!_.isEmpty(this.sort)) {
        query.sort = { ...this.sort };
      }
      return query;
    },
    /**
     * The the read only fields of the timeline components
     * loaded from configuration file
     */
    timelineReadFields() {
      return this.readFields.filter((field) => !!field.timelineMapField);
    },
    /**
     * Return the list of layouts when there is more than one
     * Otherwise return empty table when there is only one layout
     */
    layoutsIcons() {
      return Object.keys(this.layouts).length > 1 ? this.layouts : {};
    },
  },
  watch: {
    show(_current, previous) {
      if (previous !== null) {
        const map = this.$refs.map.$refs.map.mapObject;
        this.$nextTick(() => {
          map.invalidateSize();
        });
      }
    },
    focus() {
      this.activeComps.forEach((comp) => {
        this.hits[comp] = this.hits[comp].map((hit) => (
          { ...hit, focus: hit.id === this.focus }
        ));
      });
    },
    dataQuery: {
      handler() {
        this.fetchDataDebounce();
      },
      deep: true,
      immediate: true,
    },
  },
  /**
   * Initialize data
   */
  beforeMount() {
    this.show = this.defaultLayout ?? Object.keys(this.layouts).shift();

    this.activeComps.forEach((comp) => {
      this.hits[comp] = [];
    });

    // Update pagination config Table
    if (this.options.perPage) {
      this.pagination = {
        page: 1,
        itemsPerPage: this.options.perPage,
      };
    }

    this.height = _.get(this.options, 'height', 338);
  },
  mounted() {
    this.fetchDataDebounce = _.debounce(() => {
      this.fetchData();
    }, 500);

    // If height <= 0 use full-height
    if (this.height <= 0) {
      this.height = this.$el.offsetHeight;
      this.resizeListener = () => {
        this.height = this.$el.offsetHeight;
      };
      window.addEventListener('resize', this.resizeListener);
    }
    if (this.defaultLayout && this.layouts[this.defaultLayout]) {
      this.currentViewLabel = this.layouts[this.defaultLayout].label;
    }
    if (this.filtersProp) {
      this.filters = this.filtersProp.map((filter) => ({ ...filter, value: undefined }));
    }

    if (this.userPreferences
      && this.userPreferences.search && this.userPreferences.search.length > 0) {
      this.currentSearch = this.userPreferences.search;
      this.updateSearch();
    }
  },
  destroyed() {
    // Remove eventListener to prevent listener multiplication
    if (this.resizeListener !== null) {
      window.removeEventListener('resize', this.resizeListener);
    }
    // console.log('destroyed');
    this.$store.commit('crud/CLEAR_CACHE', undefined);
  },
  methods: {
    canShow(target, show) {
      return this.layouts[show].components.includes(target);
    },
    /**
     * Compute hits data for components
     * Define focus state and format in good format
     *
     * @return {Record<string, unknown>[]}
     */
    async buildHits(comp, hits) {
      let data = hits;
      // Filter with the conditions for this component
      if (typeof this.conditionsComponents[comp] !== 'undefined') {
        data = hits.filter((hit) => this.applyConditions(comp, hit));
      }

      switch (comp) {
        case 'Map':
          if (this.geoHeader) {
            // Don't show (hide) devices without coordinates on the Map
            const emptyCoords = { latitude: null, longitude: null, radius: null };
            data = data.filter((hit) => (!_.isEqual(hit.coords, emptyCoords)));
            data = await buildAllGeoObjects(data.map((hit) => ({
              ...hit,
              // Define hit focused
              focus: hit.id === this.focus,
            })), this.geoHeader);
          }
          break;

        case 'Chart':
          data = data.map((hit) => ({
            id: hit.id,
            focus: hit.id === this.focus,
            data: hit,
          }));
          break;
        default:
          break;
      }
      return data;
    },
    /**
     * Apply conditions to filter data for component
     *
     * @param {component} component name of component to get appropriated conditions
     * @param {Record<string, unknown>} hit hit to test condition
     *
     * @return {boolean} return boolean of result of test
     */
    applyConditions(comp, hit) {
      if (typeof this.conditionsComponents[comp] !== 'undefined') {
        let result = false;
        const conditionsLength = this.conditionsComponents[comp].length;
        for (let i = 0; i < conditionsLength; i += 1) {
          const { min, max, path } = this.conditionsComponents[comp][i];

          // If min is undefined it's egal -Infinity
          const minTest = (typeof min !== 'undefined') ? _.get(hit, path, 0) >= min : true;
          const maxTest = (typeof max !== 'undefined') ? _.get(hit, path, 0) <= max : true;

          if (minTest && maxTest) {
            result = true;
          }
          if (result) {
            return result;
          }
        }
        return result;
      }
      return true;
    },
    /**
     * Fetch data and update data for active components
     */
    async fetchData() {
      const data = {};
      let total = 0;
      this.loading = true;
      this.$nextTick(async () => {
        if (!_.isEmpty(this.dataQuery)) {
          const result = await this.$store.dispatch('crud/SEARCH', {
            object: this.object,
            alias: this.alias,
            body: this.dataQuery,
            all: (this.pagination.itemsPerPage === -1),
          });
          const hits = (result.body || [])
            .filter((hit) => (hit.current))
            .sort((a, b) => (a.order - b.order));

          for (let index = 0; index < this.activeComps.length; index += 1) {
            const comp = this.activeComps[index];
            if (typeof data[comp] === 'undefined') {
              data[comp] = [];
            }
            // eslint-disable-next-line no-await-in-loop
            data[comp].push(...(await this.buildHits(comp, hits)));
          }
          total = result.totalResult;
        }

        this.hits = data;
        this.total = total;

        this.$nextTick(() => {
          this.loading = false;
        });
      });
    },
    /**
     * Update focus state, when hit is clicked on components
     */
    highlight(id) {
      if (this.focus === id) {
        this.focus = null;
        this.tableFocused = [];
      } else {
        this.focus = id;
        this.tableFocused = [id];
      }
    },
    /**
     * Change the current visible layout by the selected one
     * @param {string} key the key of the selected layout
     * @param {object} layout the layout to show
     */
    switchLayout(key, layout) {
      this.show = key;
      this.currentViewLabel = layout.label;
    },
    /**
     * Update Search
     */
    updateSearch() {
      const value = this.currentSearch.length > 0 ? this.currentSearch : undefined;
      const searchIndex = this.filters.findIndex(({ search }) => search);
      if (searchIndex !== -1) {
        // this.filters[searchIndex].value = value;
        let newFilter = this.filters[searchIndex];
        newFilter = { ...newFilter, value };
        this.$set(this.filters, searchIndex, newFilter);
      }
      const prefs = { ...this.userPreferences, search: this.currentSearch };
      if (prefs.pagination && prefs.pagination.page) {
        prefs.pagination.page = 1;
      }
      this.updatePrefs(prefs);
    },
  },
};
</script>
<style>
  .v-layout-field .leaflet-top {
    z-index: 7;
  }
</style>
